import getSymbolFromCurrency from "currency-symbol-map";

export const getCurrencySymbol = (currency) => {
    if(currency === 'QAR') return 'QAR';
  if (currency) {

    const symbol = getSymbolFromCurrency(currency);
    return symbol;
  }

  return currency;
};

export const compareAndSave = (newOrders) => {
    let oldOrder = {};
    const getLocalOrder = localStorage.getItem("__lastOrders");
    if (!getLocalOrder) {
      localStorage.setItem("__lastOrders", JSON.stringify(newOrders));
    }
    if (getLocalOrder) {
      oldOrder = JSON.parse(getLocalOrder);
    }
    let result = {};
    for (let key in newOrders) {
      if (newOrders[key] && oldOrder[key] && newOrders[key] > oldOrder[key]) {
        result[key] = true;
      }
    }
    return result;
};

export const updateLocalOrderByType = (data, type) => {
  let oldOrder = {};
  const getLocalOrder = localStorage.getItem("__lastOrders");
  if (getLocalOrder) {
    oldOrder = JSON.parse(getLocalOrder);
  }
  let newOrder = { ...oldOrder, [type]: data[type] };
  localStorage.setItem("__lastOrders", JSON.stringify(newOrder));
};

export const groupByOrdersByStatus = (orderList = []) => {
  let result = {};
  if (!orderList.length) {
    return result;
  }

  result = orderList.reduce((acc, item) => {
    if (!acc[item.status]) {
      acc[item.status] = [];
    }
    acc[item.status].push(item);
    return acc;
  }, {});

  return result;
};

/* eslint-disable no-unused-expressions */
export const getItemDetail = (order) => {
  let tax = 0,
    totalAmount = 0,
    dis = 0,
    discount = [],
    paidAmount = order?.order_payments?.[0]?.amount,
    delivery_charge = 0;
  order?.order_menu_items?.forEach((item) => {
    let addonPrice = 0;
    item?.order_addons?.forEach((addon) => {
      addonPrice += addon?.price * addon?.quantity;
    });
    totalAmount = totalAmount + (item?.price + addonPrice) * item?.quantity;
  });
  order?.order_outlet_coupons?.forEach((cou) => {
    const d = {
      code: cou?.coupon_code,
      desc: cou?.coupon_description,
    };
    if (cou?.coupon_type == "percentage") {
      d["amount"] = (totalAmount * cou?.coupon_discount) / 100;
    } else if (cou?.coupon_type == "absolute") {
      d["amount"] = cou?.coupon_discount;
    }
    dis = dis + d.amount;
    discount.push(d);
  });

  tax = order?.order_detail?.amount_details?.tax;
  delivery_charge = order.order_detail?.amount_details?.delivery_charge;
  let result = {
    tax: tax,
    totalAmount: totalAmount,
    discount: discount,
    paidAmount: paidAmount,
    delivery_charge: delivery_charge,
  };
  return result;
};

export const getBlinkClass = (item, tab) => {
  // if(item.id === 7325){
  // ;
let classNameValue =  "text-center normal-blink text-dark";
  if (tab === "NewOrder") {
    // ;
    const d = new Date() - new Date(item?.order_detail.created_at);
    if (d > 5 * 60 * 1000) {
      classNameValue =  "blink  bg-danger text-white";
    }
  } else if (tab === "Accepted") {
    const d = new Date() - new Date(item?.order_detail?.accepted_at);
    if (d > 7 * 60 * 1000) {
      classNameValue =  "blink bg-warning text-white";
    }
  } else if (tab === "InKitchen") {
    // ;
    // To be implemented date will be pos
    const d = new Date() - new Date(item?.order_detail?.punchedin_at);
    if (d > 50 * 60 * 1000) {
      classNameValue =  "blink  bg-danger text-white";
    } else if (d > 40 * 60 * 1000) {
      classNameValue =  "blink  bg-orange text-white";
    } else if (d > 30 * 60 * 1000) {
      classNameValue =  "blink  bg-warning text-white";
    }
  }
  if(item?.order_detail?.service_type === 'takeaway') classNameValue += ' takeaway'
    return classNameValue;
  // }
};
export const convertJSONtoFormData = (data) => {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
};
const buildFormData = (formData, data, parentKey) => {
    if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File) &&
        !(data instanceof Blob)
    ) {
        Object.keys(data).forEach((key) => {
            buildFormData(
                formData,
                data[key],
                parentKey ? `${parentKey}[${key}]` : key
            );
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
};
