import milk from '../assets/icons/milk.png';
import egg from '../assets/icons/egg.png';
import soya from '../assets/icons/soya.png';
import nuts from '../assets/icons/nuts.png';
import gluten from '../assets/icons/gluten.png';
import treenuts from '../assets/icons/treenuts.png';
import coconut from '../assets/icons/coconut.png';
import shellfish from '../assets/icons/shellfish.png';
import vegetarian from '../assets/icons/veg.png';
import vegan from '../assets/icons/vegan.png';

export const AllergensIcon={
    milk:milk,
    egg:egg,
    soya:soya,
    nuts:nuts,
    gluten:gluten,
    treenuts:treenuts,
    coconut:coconut,
    shellfish:shellfish,
    vegetarian:vegetarian,
    vegan:vegan
}

export const AvailableAllergens=[
    {
        label: 'Milk',
        value: 'milk',
    },

    {
        label: 'Egg',
        value: 'egg',
    },

    {
        label: 'Soya',
        value: 'soya',
    },

    {
        label: 'Nuts',
        value: 'nuts',
    },

    {
        label: 'Gluten',
        value: 'gluten',
    },

    {
        label: 'Tree nuts',
        value: 'treenuts',
    },

    {
        label: 'Coconut',
        value: 'coconut',
    },

    {
        label:'Shellfish',
        value:'shellfish'
    },

    {
        label:'Vegetarian',
        value:'vegetarian'
    },

    {
        label:'Vegan',
        value:'vegan'
    }
]